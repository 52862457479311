<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">商户套餐对账</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-date-range label="日期" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-company-under-select label="商户" placeholder="请选择（可输入搜索）" v-model="pageParam.params.companyId"></le-company-under-select>
            </le-search-form>
            <le-pagview ref="financeCompany" @setData="setTableData" :pagerCount="5" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financeCompanyList">
                <el-table ref="financeCompanyList" :data="tableData" :highlight-current-row="true" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="companyName" label="商户" min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.name?row.name:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeAllAmount" min-width="150">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>总分账金额</span>
                                <span class="a-fs-12 a-c-999">搜索时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs rowBox">
                                <span>充电：￥{{ util.numFormat(row.chargeTimeAllAmount) }}</span>
                                <span>套餐：￥{{ util.numFormat(row.monthCardTimeAllAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeIncomeAmount" min-width="150">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>应分账金额</span>
                                <span class="a-fs-12 a-c-999">搜索时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs rowBox">
                                <span>充电：￥{{ util.numFormat(row.chargeTimeIncomeAmount) }}</span>
                                <span>套餐：￥{{ util.numFormat(row.monthCardTimeIncomeAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalIncomeAmount" label="应分账累计金额" min-width="150">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs rowBox">
                                <span>充电：￥{{ util.numFormat(row.chargeTotalIncomeAmount) }}</span>
                                <span>套餐：￥{{ util.numFormat(row.monthCardTotalIncomeAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalIncomeBalance" label="应分账累计余额" min-width="150">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs rowBox">
                                <span>充电：￥{{ util.numFormat(row.chargeTotalIncomeBalance) }}</span>
                                <span>套餐：￥{{ util.numFormat(row.monthCardTotalIncomeBalance) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="countDate" min-width="150">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>已入账金额</span>
                                <span class="a-fs-12 a-c-999">搜索时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs rowBox">
                                <span>充电：￥{{ util.numFormat(row.chargeTimeRecordAmount) }}</span>
                                <span>套餐：￥{{ util.numFormat(row.monthCardTimeRecordAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalRecordAmount" label="累计入账金额" min-width="150">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs rowBox">
                                <span>充电：￥{{ util.numFormat(row.chargeTotalRecordAmount) }}</span>
                                <span>套餐：￥{{ util.numFormat(row.monthCardTotalRecordAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalRecordAmount" label="" min-width="140">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>套餐卡当期收入</span>
                                <span class="a-fs-12 a-c-999">搜索时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.dayMonthCardAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="countDate" min-width="140">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>套餐卡累计收入</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.totalMonthCardAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeWithdraw" label="提现" min-width="123">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.timeWithdraw) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalWithdraw" label="累计提现" min-width="123">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.totalWithdraw) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyBalance" label="商户余额" min-width="123">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.companyBalance) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getBillsInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsc">
                        <div class="a-flex-rfsc" style="margin-right: 40px">
                            <span class="a-fs-12 a-c-normal">提现总额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.withdraw)}}</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="a-fs-12 a-c-normal">累计提现总额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.totalWithdraw)}}</span>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.companyWithdrawPage,
                    method: "post",
                    params: {
                        companyId: '',
                        endDate: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                        startDate: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
            }
        },
        created () {
            this.pageParam.params.companyId = this.company.id
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
            }),
        },
        methods: {
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: this.company.id,
                    endDate: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                    startDate: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                this.$refs['financeCompany'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 查看详情
            getBillsInfo (datas) {
                this.$router.push({
                    path: '/finance/finance-company-details',
                    query: {
                        companyId: datas.companyId,
                        startDate: this.pageParam.params.startDate,
                        endDate: this.pageParam.params.endDate
                    }
                })
            },
            //条件筛选
            filterFun(value){
                // for(var key in value){
                //     if(key=='incomeStatus'){
                //         if(value[key].length==0){
                //             this.pageParam.params.incomeStatus = ''
                //         }else{
                //             this.pageParam.params.incomeStatus = value[key][0]
                //         }
                //     }
                //     if(key=='incomeType'){
                //         if(value[key].length==0){
                //             this.pageParam.params.incomeType = ''
                //         }else{
                //             this.pageParam.params.incomeType = value[key][0]
                //         }
                //     }
                // }
                // this.$refs['financeSYRZ'].pageNum = 1
                // this.pageParam.freshCtrl++;
            },

            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 12,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 商户对账表',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    .rowBox{
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        flex-direction: column;
        font-size: 12px !important;
    }
</style>